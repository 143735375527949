/**
 * @module Directives.FeedFilters
 *
 */
import moment from 'moment';
import {
  uniq
} from 'app/utils/array';
import {
  run
} from 'app/utils/runloop';
import template from 'ngapp/templates/views/feed_filters/_filters.html';
import without from 'lodash/without';
import {
  YEAR_MIN
} from 'ngapp/directives/feed_filters/year_picker';

const FROM_DATE_VALUE = moment().year(YEAR_MIN).startOf('year');

/**
 * @class Filters
 *
 */
function Controller($scope, $translate) {
  $scope.MIN_YEAR_VALUE = FROM_DATE_VALUE.valueOf();
  $scope.form = createForm();
  $scope.resourceTypes = createResourceTypeOptions();
  $scope.dirty = false;
  $scope.onSubmit = onSubmit;
  $scope.onReset = onReset;
  $scope.inputTags = [];
  $scope.orderOptions = [{
      value: "created_at",
      label: $translate.instant('FEED_FILTER.ORDER_TYPES.CREATED_AT').toString()
    },
    {
      value: "captured_at",
      label: $translate.instant('FEED_FILTER.ORDER_TYPES.CAPTURED_AT').toString()
    }
  ];
  $scope.selectedOrder = $scope.orderOptions[0];
  $scope.form.orderBy = $scope.selectedOrder.value;
  $scope.fromMaxDate = moment().valueOf();
  $scope.toMaxDate = moment().valueOf();
  $scope.selectedAuthors = [];
  $scope.onFromDatesError = null;
  $scope.onToDatesError = null;
  $scope.isDemo = $scope.$props.isDemo;
  $scope.onDemoFilterClick = onDemoFilterClick;
  $scope.showDemoPostModal = false;
  $scope.demoPostType = '';

  $scope.onSelectAuthors = (data) => {
    run(() => {
      data.map(item => {
        if (!$scope.selectedAuthors.includes(item.model)) {
          $scope.selectedAuthors.push(item.model);
        }
        if (!$scope.form.userIds.includes(item.value)) {
          $scope.form.userIds.push(item.value);
        }
      });
    });
  };

  function onDemoFilterClick() {
    $scope.showDemoPostModal = true;
    $scope.demoPostType = 'filter';
  }

  $scope.closeDemoPost = () => {
    $scope.showDemoPostModal = false;
  };

  $scope.onRemoveSelectedAuthors = (item) => {
    run(() => {
      $scope.selectedAuthors = without($scope.selectedAuthors, item);
      $scope.form.userIds = without($scope.form.userIds, item.id);
    });
  };

  $scope.onSelectOrder = (value) => {
    run(() => {
      if (value != null) {
        $scope.selectedOrder = value;
        $scope.form.orderBy = value.value;
      }
    });
  };

  $scope.onUpdateTags = (tags) => {
    tags = tags != null ? tags : [];
    const values = tags.map(item => item.name);
    const curTagsAdded = ($scope.inputTags || []).slice(0).filter(item => values.indexOf(item) !== -1);
    const currentTags = ($scope.form.tags || []).slice(0).filter(item => values.indexOf(item) !== -1);
    const newTags = (values || []).slice(0).filter(item => currentTags.indexOf(item) === -1);

    $scope.inputTags = uniq([...curTagsAdded, ...newTags]);
    $scope.form.tags = uniq([...currentTags, ...newTags]);
  };

  $scope.onFromYearChange = ({
    value
  }) => run(() => {
    $scope.form.fromYear = value;
    if (value != null) {
      if (moment().year(value).format('YYYY') === moment().format('YYYY')) {
        $scope.fromMaxDate = moment().valueOf();
      } else {
        $scope.fromMaxDate = null;
      }
    }
    serializeFilters();
  });

  $scope.onToYearChange = ({
    value
  }) => run(() => {
    $scope.form.toYear = value;
    if (value != null) {
      if (moment().year(value).format('YYYY') === moment().format('YYYY')) {
        $scope.toMaxDate = moment().valueOf();
      } else {
        $scope.toMaxDate = null;
      }
    }
    serializeFilters();
  });

  $scope.onFromMonthChange = ({
    value
  }) => run(() => {
    $scope.form.fromMonth = value;
    serializeFilters();
  });

  $scope.onToMonthChange = ({
    value
  }) => run(() => {
    $scope.form.toMonth = value;
    serializeFilters();
  });

  $scope.onFromDateChange = ({
    value
  }) => run(() => {
    $scope.form.fromDate = value;
    $scope.onFromDatesError = null;
    serializeFilters();
  });

  $scope.onToDateChange = ({
    value
  }) => run(() => {
    $scope.form.toDate = value;
    $scope.onToDatesError = null;
    serializeFilters();
  });

  function onReset() {
    $scope.dirty = false;
    $scope.form = createForm();
    $scope.selectedAuthors = [];
    $scope.$props.onFilter({
      filters: null
    });
    $scope.onToDatesError = null;
    $scope.onFromDatesError = null;
  }

  function createResourceTypeOptions() {
    return [{
        value: 'image',
        label: $translate.instant('FEED_FILTER.RESOURCE_TYPES.IMAGES')
      },
      {
        value: 'video',
        label: $translate.instant('FEED_FILTER.RESOURCE_TYPES.VIDEO')
      },
      {
        value: 'note',
        label: $translate.instant('FEED_FILTER.RESOURCE_TYPES.NOTES')
      },
      {
        value: 'audio',
        label: $translate.instant('FEED_FILTER.RESOURCE_TYPES.AUDIO')
      },
      {
        value: 'datafile',
        label: $translate.instant('FEED_FILTER.RESOURCE_TYPES.DOCUMENTS')
      },
    ];
  }

  function createForm() {
    // var now = moment();
    var minYear = moment().year(YEAR_MIN);


    return {
      orderBy: 'created_at',
      tags: [],
      type: {
        image: true,
        video: true,
        note: true,
        audio: true,
        datafile: true
      },
      fromYear: minYear.format('YYYY'),
      // toYear: now.format('YYYY'),
      // fromMonth: 'Jan',
      // toMonth: now.format('MMM'),
      // fromDate: now.format('DD'),
      // toDate: now.startOf('MMM').format('DD'),
      toYear: '',
      fromMonth: '',
      toMonth: '',
      fromDate: '',
      toDate: '',
      userIds: [],
    };
  }


  function serializeFilters() {
    // var filters = {
    //   orderBy: $scope.form.orderBy,
    //   tags: $scope.form.tags,
    //   type: Object.keys($scope.form.type).filter(key => $scope.form.type[key] === true),
    //   userIds: $scope.form.userIds,
    //   from: null,
    //   to: null,
    // };

    var filters = {
      orderBy: $scope.form.orderBy,
      tags: $scope.form.tags,
      type: Object.keys($scope.form.type).filter(key => $scope.form.type[key] === true),
      userIds: $scope.form.userIds,
    };

    // check if user has selected the date inputs
    if ($scope.form.fromYear || $scope.form.toYear || $scope.form.fromMonth || $scope.form.toMonth || $scope.form.fromDate || $scope.form.toDate) {
      // checking if from year is selected
      $scope.onFromDatesError = null;
      if ($scope.form.fromYear) {
        filters.from = moment().year($scope.form.fromYear).month($scope.form.fromMonth && $scope.form.fromYear ? $scope.form.fromMonth : 0).date($scope.form.fromDate ? $scope.form.fromDate : '01').toISOString();
        $scope.onFromDatesError = null;
        // checking if any till input is selected
        if ($scope.form.toYear || $scope.form.toMonth || $scope.form.toDate) {
          $scope.onToDatesError = null;
          // checking if from year and to year inputs are selected
          if ($scope.form.toYear && $scope.form.fromYear) {
            filters.to = moment().year($scope.form.toYear).month($scope.form.toMonth ? $scope.form.toMonth : 0).date($scope.form.toDate ? $scope.form.toDate : '01').toISOString();
            $scope.onToDatesError = null;
            return filters;
          } else {
            // till year is not selected
            $scope.onToDatesError = 'Please select a year';
            return;
          }
        }

        return filters;
      } else {
        // from year is not selected
        $scope.onFromDatesError = 'Please select a year';

        return;
      }
    } else {
      // the date inputs are not selected
      return filters;
    }
  }

  function onSubmit(e) {
    e.preventDefault();
    if (!$scope.onToDatesError || !$scope.onFromDatesError) {
      $scope.dirty = true;
      $scope.$props.onFilter({
        filters: serializeFilters()
      });
    }
  }
}

Controller.$inject = ['$scope', '$translate'];

export default {
  bindings: {
    onFilter: '&',
    isDemo: '<',
  },
  template,
  controller: Controller,
  controllerAs: '$props'
};
